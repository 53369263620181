import React from "react";
import { Helmet } from "react-helmet";

const Brokerage = () => {
  return (
    <div>
      <Helmet>
        <title>Fabits - Brokerage</title>
        <meta
          name="description"
          content="Explore affordable and flexible investment plans with Fabits, tailored to fit every budget and designed to help you achieve your financial goals."
        />
      </Helmet>

      <div className="pricingpagebottomsection">
        <h2 className="Additioinalinfoheadingpricingpage">
          Brokerage Information
        </h2>
        <div className="additionalinformationandtextbeside">
          <div className="additionalinformationtablesection">
            <table className="additionnalinformationtablesectiontable">
              <tr>
                <th
                  colSpan={2}
                  className="tableheedingadditionalinformationsection"
                >
                  Detailed Pricing
                </th>
              </tr>
              <tr>
                <td className="tabledataoftheadditionalsection leftpartthing">
                  Brokerage
                </td>
                <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                  &#8377; 20 + GST
                </td>
              </tr>
              <tr>
                <td className="tabledataoftheadditionalsection leftpartthing">
                  Charges
                </td>
                <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                  <strong>Resident Indian:</strong> Equity Delivery<br></br>
                  <strong>NRI: &nbsp; </strong>
                  PIS
                </td>
              </tr>
              <tr>
                <td className="tabledataoftheadditionalsection leftpartthing">
                  STT/ CTT
                </td>
                <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                  0.1% on Buy & Sell
                </td>
              </tr>
              <tr>
                <td className="tabledataoftheadditionalsection leftpartthing">
                  Transaction Charges
                </td>
                <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                  NSE: 0.00345%
                </td>
              </tr>
              <tr>
                <td className="tabledataoftheadditionalsection leftpartthing">
                  GST
                </td>
                <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                  18% on transaction charges
                </td>
              </tr>
              <tr>
                <td className="tabledataoftheadditionalsection leftpartthing">
                  SEBI Charges
                </td>
                <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                  &#8377; 10 per crore
                </td>
              </tr>
              <tr className="lasttableitempricepage">
                <td className="tabledataoftheadditionalsection leftpartthing lastboxofadditionalinformation">
                  Stamp Charges
                </td>
                <td className="tabledataoftheadditionalsection lastboxofadditionalinformation rightanswertothequestionthing">
                  0.015% or ₹ 1500/crore on buy side
                </td>
              </tr>
            </table>
          </div>
          <div className="textsectionbesideadditionalinformation">
            <h4 className="textsectionbesideadditionalinformationh4">
              Securities Transaction Tax (STT)/ Commodities Transaction Tax
              (CTT)
            </h4>
            <p className="textsectionbesideadditionalinformationp">
              STT is the tax levied by the Government when transacting on the
              securities market through any of the stock exchanges. It is levied
              on both “Buy” and “Sell” side when trading results in delivery and
              only on sell side when trading does not result in Delivery (like
              intraday or in F&O). Trading in the currency segment is exempt
              from STT.
            </p>
            <h4 className="textsectionbesideadditionalinformationh4">
              Exchange Transaction Charges
            </h4>
            <p className="textsectionbesideadditionalinformationp">
              These charges are levied by Exchanges on the traded volume of the
              customer. This is generally specified in terms of Rupees per Crore
              of Turnover. The charge includes:
            </p>
            <h4 className="textsectionbesideadditionalinformationh4">
              Exchange transaction charges + Clearing Member charges
            </h4>
            <p className="textsectionbesideadditionalinformationp">
              Charged by exchanges (NSE, BSE, MCX) and Clearing members.
            </p>
            <h4 className="textsectionbesideadditionalinformationh4">GST</h4>
            <p className="textsectionbesideadditionalinformationp">
              Tax levied by the government on the services rendered is
              calculated presently @ <strong>18%</strong> on brokerage,
              transaction charges and any other charges for services rendered by
              the broker or the exchange.
            </p>
            <h4 className="textsectionbesideadditionalinformationh4">
              SEBI Charges
            </h4>
            <p className="textsectionbesideadditionalinformationp lastparaoftheadditionalsection">
              The SEBI charges are calculated presently @ ₹ 10/ 1 crore (w.e.f.
              01.06.2021) for both equity and commodity trades on the traded
              volume of the customer in all segments.
            </p>
            {/* <p className="textsectionbesideadditionalsectionlastphrase">
              * Regular Mutual Funds
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brokerage;
