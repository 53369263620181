import React from "react";
import styles from "./getstarted.module.css";

import { getStartedImages } from "../../../assets/newHomepageImages";

const GetStarted = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <img
            className={styles.doodle}
            src={getStartedImages.doodle}
            alt="doodle to left"
          />
          <div>
            <h1>Get Started</h1>
            <p>
              Connect with 2500+ users already planning their financial career
            </p>
            <div className={styles.emailInput}>
              {/* <input
                type="tel"
                name="email"
                //   id="email"
                placeholder="Enter phone number"
              /> */}
              <button>
                <a
                  href="https://mywealth.fabits.com/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign Up
                </a>
              </button>
            </div>
          </div>
          <div>
            <img src={getStartedImages.pixel} alt="Pixel 7 Pro" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
