import React from "react";
import styles from "./HeroSection.module.css";
import {heroSection} from '../../../assets/newHomepageImages'

const trustedComps = [
  { id: 1, logo: heroSection.Thermo },
  { id: 2, logo: heroSection.Infosys },
  { id: 3, logo: heroSection.GoDaddy },
  { id: 4, logo: heroSection.Flipkart },
  { id: 5, logo: heroSection.Tavant },
  { id: 6, logo: heroSection.Hsbc },
  { id: 7, logo: heroSection.Intel },
  { id: 8, logo: heroSection.Nvidia },
];

const HeroSection = () => {
  return (
    <>
      <div className={styles.content}>
        <h1>Wealth Building, Made Personal</h1>
        <p>
          Build lasting wealth with personalised guidance and intelligent
          financial tools.
        </p>

        <div>
          <p>Professional led financial insights</p>
          <p>Goal-based Investment</p>
          <p>25+ Years in Wealth Management</p>
        </div>

        <div>
          <a
            href="https://start.fabits.com/?utm_source=standalone"
            target="_blank"
            rel="noreferrer"
          >
            <button>Get Started</button>
          </a>
        </div>
      </div>
      <div className={styles.logos}>
        <p>Trusted by employees working in</p>
        <div className={styles["logo-container"]}>
          {trustedComps.map((comp) => {
            return <img src={comp.logo} key={comp.id} alt={comp.id} />;
          })}
          {trustedComps.map((comp) => (
            <img src={comp.logo} key={`duplicate-${comp.id}`} alt={comp.id} />
          ))}
        </div>
      </div>
    </>
  );
};

export default HeroSection;
