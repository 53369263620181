import React, { useEffect } from "react";
import "./Termsandconditions.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import Accordian from "../components/Invester_Complaints/Accordian";
import clevertap from "clevertap-web-sdk";
import { Complaints } from "../assets/fabits_assets";

const accordionData = [
  {
    id: "FY 21-22",
    title: "FY 21-22",
    content: (
      <div className="linksandbuttonsofincvestorcomplaints">
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.DEC_21}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Dec 2021
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JAN_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jan 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.FEB_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Feb 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.MAR_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Mar 2022
            </a>
          </h6>
        </div>
      </div>
    ),
  },
  {
    id: "FY 22-23",
    title: "FY 22-23",
    content: (
      <div className="linksandbuttonsofincvestorcomplaints">
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.APR_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Apr 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.MAR_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              May 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JUN_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jun 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JUL_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jul 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.AUG_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Aug 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.SEP_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Sep 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.OCT_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Oct 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.NOV_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Nov 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.DEC_22}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Dec 2022
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JAN_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jan 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.FEB_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Feb 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.MAR_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Mar 2023
            </a>
          </h6>
        </div>
      </div>
    ),
  },
  {
    id: "FY 23-24",
    title: "FY 23-24",
    content: (
      <div className="linksandbuttonsofincvestorcomplaints">
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.APR_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Apr 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.MAY_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              May 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JUN_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jun 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JUL_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jul 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.AUG_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Aug 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.SEP_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Sep 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.OCT_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Oct 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.NOV_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Nov 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.DEC_23}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Dec 2023
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JAN_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jan 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.FEB_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Feb 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.MAR_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Mar 2024
            </a>
          </h6>
        </div>
      </div>
    ),
  },
  {
    id: "FY 24-25",
    title: "FY 24-25",
    content: (
      <div className="linksandbuttonsofincvestorcomplaints">
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.APR_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Apr 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.MAY_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              May 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JUN_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              June 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JUL_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              July 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.AUG_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Aug 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.SEP_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Sep 2024
            </a>
          </h6>
        </div>
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.OCT_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Oct 2024
            </a>
          </h6>
        </div>

        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.NOV_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Nov 2024
            </a>
          </h6>
        </div>

        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.DEC_24}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              DEC 2024
            </a>
          </h6>
        </div>
      </div>
    ),
  },
  {
    id: "FY 25-26",
    title: "FY 25-26",
    content: (
      <div className="linksandbuttonsofincvestorcomplaints">
        <div className="cardsbodyforinvestorpage">
          <h6 className="card-title">
            <a
              href={Complaints.JAN_25}
              target="_blank"
              className="text-light text-center"
              rel="noreferrer"
            >
              Jan 2025
            </a>
          </h6>
        </div>
      </div>
    ),
  },
];

function InvestorComplaints({ data, title }) {
  const location = useLocation();
  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    clevertap.event.push("Page Viewed", {
      Page_name: "Investor Complaints",
    });
  }, []);
  return (
    <>
      <div className="termsandconditionspage">
        <Helmet>
          <title>Fabits - Investor Complaints</title>
          <meta
            name="description"
            content="Regulatory page displaying the various complaints received by Shareway Securities Private Limited"
          />
        </Helmet>
        {title && (
          <div className="termsandconditionsheeding">
            <h1>Investor Complaints</h1>
          </div>
        )}
        <div className="contentstartshereinvestor">
          {data && (
            <h2 className="sharewaysecuritieslimitedininvestorcomplainspage">
              Data
            </h2>
          )}

          <Accordian Data={accordionData} />
          <div className="Trendofanualdepoits">
            <h2 className="sharewaysecuritieslimitedininvestorcomplainspage">
              Trend of annual disposal of complaints
            </h2>
            <table className="table-investorcharter">
              <thead>
                <tr>
                  <th scope="col" className="annualtrendtable">
                    SI No
                  </th>
                  <th scope="col" className="annualtrendtable">
                    Year
                  </th>
                  <th scope="col" className="annualtrendtable">
                    Carried Forward
                  </th>
                  <th scope="col" className="annualtrendtable">
                    Received
                  </th>
                  <th scope="col" className="annualtrendtable">
                    Resolved
                  </th>
                  <th scope="col" className="annualtrendtable">
                    Pending
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>2021 - 2022</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>2022 - 2023</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>2023 - 2024</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="NotificationsandCirculars">
            <h2 className="sharewaysecuritieslimitedininvestorcomplainspage">
              Notifications and Circulars
            </h2>
            <p>
              For circulars from NSE,&nbsp;
              <a href="https://www.nseindia.com/regulations/member-circulars-for-members">
                Click Here.
              </a>
            </p>
            <p>
              For circulars from SEBI,&nbsp;
              <a href="https://www.sebi.gov.in/sebiweb/home/HomeAction.do?doListing=yes&amp;sid=1&amp;ssid=7&amp;smid=0">
                Click Here.
              </a>
            </p>
            <p className="pb-5 mb-5">
              For circulars from CDSL,&nbsp;
              <a href="https://www.cdslindia.com/Publications/Communique.aspx">
                Click Here.
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestorComplaints;
